<template>
  <section class="columns is-centered">
    <div class="column is-two-thirds">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://br.zune.money/">
            <strong>ZuneMoney</strong><span class="is-size-7">Beta</span></a>
            <!-- zune -->

          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item is-hidden">
              Comunidade
            </a>
            <a class="navbar-item is-hidden">
              Blog
            </a>
            <a class="navbar-item" href="#dividend-tracker">
              Acompanhe Dividendos
            </a>
            <a class="navbar-item" href="#portfolio-performance">
              Comparar Carteira
            </a>
            <a class="navbar-item" href="https://app.zune.money/demo">
              Demo
            </a>
            <div class="navbar-item has-dropdown is-hoverable is-hidden">
              <a class="navbar-link">
                Features
              </a>
              <div class="navbar-dropdown">
                <a class="navbar-item">
                  Dividend Tracking
                </a>
                <a class="navbar-item">
                  Performance comparison
                </a>
                <!-- <a class="navbar-item is-hidden">
                                    Dividend Alerts
                                </a> -->
                <hr class="navbar-divider">
                <a class="navbar-item">
                  Demo
                </a>
              </div>
            </div>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <a class="button is-primary" href="https://app.zune.money/signup">
                  <strong>Cadastrar</strong>
                </a>
                <a class="button is-light" href="https://app.zune.money">
                  Entrar
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
  <section class="columns is-centered has-background-white-ter">
    <div class="column is-two-thirds">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="columns">
            <div class="column">
              <h1 class="has-text-weight-bold is-size-3">A melhor maneira de acompanhar sua carteira de investimentos</h1>
              <h2>Simples de usar e descomplicado. Visualize todos os seus investimentos em um único lugar.</h2>

              <form method="get" action="https://app.zune.money/signup" class="field is-grouped mt-3">

                <p class="control is-expanded">
                  <input class="input is-medium" name="email" type="text" placeholder="Digite seu email...">
                </p>
                <p class="control">
                  <button class="button is-primary is-medium has-text-weight-semibold">
                    🚀 Cadastrar
                  </button>
                </p>
                <!-- </form> -->

              </form>
            </div>
          </div>
          <figure class="image">
            <img src="/images/zune1.png" alt="ZuneMoney - Acompanhamento de carteira de investimentos">
          </figure>
        </div>
      </section>
    </div>
  </section>
  <section class="columns is-centered">
    <div class="column is-two-thirds">
      <!-- <h3>Features</h3> -->
      <div class="section">
        <div class="columns is-hidden">
          <div class="column">
            <h4 class="has-text-weight-bold">Acompanhe açōies do mundo inteiro</h4>
            <p>Explore opçōes de investimentos internacionais com ZuneMoney. Monitore e gerencie facilmente seu
              portfólio global de ações, desde gigantes de tecnologia do Vale do Silício até empresas de grande porte
              europeias, tudo em uma plataforma fácil de usar.</p>
          </div>
          <div class="column"></div>
        </div>
        <h4 id="dividend-tracker" class="has-text-weight-bold is-size-4">Monitore todos os seus dividendos</h4>
        <div class="columns mt-3 is-vcentered">
          <div class="column">
            <p>Com o ZuneMoney, acompanhar sua renda de dividendos nunca foi tão fácil. Nossa plataforma intuitiva
              automatiza o processo, permitindo que você acompanhe e visualize facilmente seus ganhos com dividendos,
              eliminando a necessidade de planilhas e cálculos manuais.</p>
          </div>
          <div class="column">
            <figure class="image">
              <img src="/images/zune-dividend-tracking-history.png" alt="Dividend history chart">
            </figure>
          </div>
        </div>
        <div class="columns is-hidden">
          <div class="column">
            <h4 class="has-text-weight-bold">Visualize Ganhos Históricos</h4>
            <p>Obtenha insights valiosos sobre sua jornada financeira com o ZuneMoney. Nosso aplicativo fornece uma
              representação clara e visual de seus ganhos históricos, permitindo que você acompanhe o progresso financeiro
              ao longo do tempo e tome decisões de investimento informadas com facilidade.</p>
          </div>
          <div class="column"></div>
        </div>

        <h4 id="portfolio-performance" class="has-text-weight-bold is-size-4 mt-3">Compare o desempenho</h4>
        <div class="columns mt-5 is-vcentered">
          <div class="column">
            <figure class="image">
              <img src="/images/zune-portfolio-performance.png" alt="Compare sua carteira">
            </figure>
          </div>
          <div class="column">
            <p>ZuneMoney capacita você a avaliar o desempenho da sua carteira de investimentos como um profissional.
              Compare facilmente seus retornos com índices de mercado-chave como S&P 500, Dow Jones e NASDAQ. Tome
              decisões orientadas por dados e meça o sucesso de seus investimentos com confiança.</p>
          </div>
        </div>

        <h4 class="has-text-weight-bold is-size-4 mt-3">Companhe o histórico de dividendos da sua carteira</h4>
        <div class="columns mt-5 is-vcentered">
          <div class="column">
            <p>Acompanhe o Histórico de Dividendos: Explore o passado e faça escolhas de investimento informadas com o
              ZuneMoney. Nosso aplicativo oferece acesso abrangente aos históricos de dividendos para ações individuais.
              Descubra o histórico de dividendos de uma ação, avalie sua confiabilidade e tome decisões bem fundamentadas
              com base em dados históricos.</p>
          </div>
          <div class="column">
            <figure class="image">
              <img src="/images/zune-dividend-history.png" alt="Histórico de dividendos da carteira">
            </figure>
          </div>
        </div>

        <h4 class="has-text-weight-bold is-size-4 mt-3">Gerencie com Multi-moedas</h4>
        <div class="columns mt-5 is-vcentered">
          <div class="column">
            <figure class="image">
              <img src="/images/zune-multicurreny-support.png" alt="Carteira de investimentos com várias moedas">
            </figure>
          </div>
          <div class="column">Gerencie Multi-Moedas: No cenário financeiro globalizado de hoje, o ZuneMoney simplifica o
            investimento internacional. Nossa plataforma oferece suporte perfeito para várias moedas, garantindo que você
            possa gerenciar eficientemente seus diversos investimentos internacionais sem o incômodo de conversões de
            moeda complexas.</div>
        </div>
      </div>
    </div>
  </section>
  <section class="columns is-centered has-background-white-ter">
    <div class="column is-two-thirds">
      <div class="section">
        <div class="columns">
          <section class="column">
            <h3 class="has-text-weight-bold is-size-4 mt-3">FAQ - Perguntas e Responsas</h3>
            <ul>
              <li>
                <article class="has-text-weight-semibold mt-3">
                  <h2>Como posso acompanhar minha renda de dividendos?</h2>
                  <p class="has-text-weight-light">
                    Acompanhar sua renda de dividendos com o ZuneMoney é simples. Você pode monitorar facilmente seus
                    ganhos com dividendos adicionando os detalhes de seus investimentos no aplicativo. O sistema
                    automatizado do ZuneMoney continuará rastreando continuamente seus investimentos e fornecendo insights
                    em tempo real sobre a renda de dividendos. Você pode analisar seus ganhos mensalmente, anualmente ou
                    de forma personalizada, tudo sem a necessidade de cálculos manuais ou planilhas.
                  </p>
                </article>
              </li>
              <li>
                <article class="has-text-weight-semibold mt-3">
                  <h2>Posso receber alertas para próximos pagamentos de dividendos?</h2>
                  <p class="has-text-weight-light">
                    O ZuneMoney oferece um recurso de alerta conveniente para ajudá-lo a ficar informado sobre os próximos
                    pagamentos de dividendos. Para configurar lembretes de pagamento de dividendos, acesse as
                    configurações do aplicativo e configure alertas personalizados. Com as notificações oportunas do
                    ZuneMoney, você nunca mais perderá uma data de pagamento de dividendos. Isso elimina a necessidade de
                    rastreamento manual e garante que você esteja sempre atualizado com sua renda de investimento.
                  </p>
                </article>
              </li>
              <li>
                <article class="has-text-weight-semibold mt-3">
                  <h2>Quão precisos são os dados de dividendos e rendimento?</h2>
                  <p class="has-text-weight-light">
                    O ZuneMoney está empenhado em fornecer dados de dividendos e informações de rendimento precisos e
                    confiáveis. Obtemos nossos dados de fontes financeiras respeitáveis e mantemos atualizações regulares
                    para garantir precisão. Embora busquemos precisão, por favor, tenha em mente que o mercado de ações
                    pode sofrer flutuações, potencialmente impactando os dados de dividendos. No entanto, esteja certo de
                    que fazemos todo o esforço para fornecer informações confiáveis para apoiar suas decisões financeiras.
                  </p>
                </article>
              </li>
              <li>
                <article class="has-text-weight-semibold mt-3">
                  <h2>Posso usar o ZuneMoney em vez de uma planilha de acompanhamento de portfólio baseada no Excel?
                  </h2>
                  <p class="has-text-weight-light">
                    Sem dúvida! O ZuneMoney oferece uma alternativa dinâmica ao acompanhamento tradicional de portfólio
                    baseado no Excel. Com nossa interface amigável e recursos poderosos, você pode gerenciar seus
                    investimentos com facilidade, analisar o desempenho e obter insights valiosos - tudo em um só lugar.
                    Ao contrário das planilhas estáticas do Excel, o ZuneMoney fornece dados atualizados, atualizações
                    automáticas e uma visão abrangente do seu portfólio, economizando tempo e melhorando sua experiência
                    de investimento. Diga adeus às entradas manuais e olá à gestão de portfólio inteligente e eficiente
                    com o ZuneMoney! 📈💼
                  </p>
                </article>
              </li>
              <li>
                <article class="has-text-weight-semibold mt-3">
                  <h2>Posso acompanhar minha carteira DEGIRO com o ZuneMoney usando arquivos CSV?</h2>
                  <p class="has-text-weight-light">
                    Com certeza! Embora o ZuneMoney ofereça suporte completo ao rastreamento da carteira DEGIRO, é
                    importante notar que, atualmente, a integração está disponível por meio de arquivos CSV. Basta
                    exportar os dados da sua carteira DEGIRO em formato CSV e importá-los facilmente para o ZuneMoney.
                    Nossa plataforma garante uma experiência perfeita, permitindo que você analise, visualize e gerencie
                    sua carteira DEGIRO com facilidade. Mantenha-se atualizado sobre seus investimentos aproveitando o
                    poder da interface amigável do ZuneMoney e dos recursos abrangentes de rastreamento! 📈🔄
                  </p>
                </article>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </section>
  <section class="columns is-centered">
    <div class="column is-two-thirds">
      <footer class="section">
        <div class="columns">
          <h4 class="column">
            <strong property="dct:title" rel="dct:type">ZuneMoney</strong>App
            <br /><small>The easy way to visualize your money.</small>
          </h4>
        </div>
        <div class="columns">
          <div class="column">
            <small>©2021 Zune Money | All rights reserved</small>
          </div>
          <div class="column is-hidden">
            Community | Blog
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <small>Contact us via <a href="mailto:contact@zune.money">contact@zune.money</a></small>
          </div>
        </div>
        <!-- <div
                    class="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-8 lg:mt-12">
                    <nav class="flex flex-wrap justify-center space-x-6">
                        <a href="#" class=" text-sm hover:text-gray-700 mb-2">Contact</a>
                        <a href="#" class=" text-sm hover:text-gray-700 mb-2">Blog</a>
                        <a href="#" class=" text-sm hover:text-gray-700 mb-2">About</a>
                    </nav>
                    <nav class="flex items-center space-x-2">
                    <a href="#" class="text-gray-500 hover:text-gray-600">
                      <span class="sr-only">Facebook</span>
                      <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
                      </svg>
                    </a>
                    <a href="#" class="text-gray-500 hover:text-gray-600">
                      <span class="sr-only">Instagram</span>
                      <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
                      </svg>
                    </a>
                    <a href="#" class="text-gray-500 hover:text-gray-600">
                      <span class="sr-only">Twitter</span>
                      <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                      </svg>
                    </a>
                  </nav> 
                </div> -->
      </footer>
    </div>
  </section>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {}
}
</script>

<style>#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-top: 15px;
}</style>
